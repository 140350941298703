
  .c-alert {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: fit-content;
    margin: 0 0 0.9375rem 0;
    padding: 0.75rem 2rem 0.75rem 0.75rem;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 4px;
    color: #fff; }

.c-alert .c-close {
position: absolute;
top: 0.75rem;
right: 1rem; 
cursor: pointer;
}

.c-alert.fade {
-webkit-transition: opacity 0.3s;
transition: opacity 0.3s;
opacity: 0; }
.c-alert.fade.show {
opacity: 1; }

.c-alert__icon {
position: relative;
bottom: 0;
margin-right: 0.625rem;
font-size: 1.125rem;
opacity: 0.7; }

.c-alert--success {
  background-color: #1bb934;
}

.c-alert--info {
  background-color: #2ea1f8; }

.c-alert--warning {
  background-color: #fd9a18; }

.c-alert--danger {
  background-color: #ed1c24; }

  .u-mb-xlarge {
    margin-bottom: 6.25rem !important; }

    .u-mb-medium {
      margin-bottom: 1.875rem !important; }   

      .c-close {
        padding: 0;
        border: 0;
        background-color: transparent;
        color: #fff;
        line-height: 1;
        opacity: 0.7;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none; }
        .c-close:hover, .c-close:focus {
          opacity: 1; }
        .c-close:focus {
          outline: 0; }